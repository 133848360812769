import React from "react";
import styled from "styled-components";
import { BodyIntro } from "../styles/TextStyles";
import { menuData } from "../../data/menuData";
import MenuButton from "../buttons/MenuButton";

export default function Header() {
  return (
    <Wrapper>
      <ContentWrapper>
        <LogoWrapper>
          <Logo
            className="w-8 h-8 md:w-14 md:h-14 text-blue-200 font-sh"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            ></path>
          </Logo>
          <LogoText>Budaya AI</LogoText>
        </LogoWrapper>
        <MenuWrapper count={menuData.length}>
          {menuData.map((item, index) => (
            <MenuButton item={item} key={index} />
          ))}
          {/* <MenuTooltip /> */}
        </MenuWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: auto;

  /* background-color: tomato; */

  @media (max-width: 768px) {
    top: 30px;
  }

  @media (max-width: 450px) {
    top: 30px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 240px auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  justify-self: center;
  /* justify-items: center; */
  max-width: 1280px;
  padding: 0 30px;
  margin: 0 18px;

  @media (max-width: 1024px) {
    width: unset;
    justify-self: unset;
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    margin: 0;
  }

  @media (max-width: 450px) {
    grid-template-columns: auto;
  }
`;

const MenuWrapper = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(${(props) => props.count}, auto);
  align-items: center;
  justify-content: center;
  @media (max-width: 450px) {
    gap: 15px;
  }
`;

const LogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 44px auto;
  align-items: center;
  gap: 10px;
  opacity: 0.8;
`;

const Logo = styled.svg`
  width: 44px;
  height: auto;
  color: white;
  text-shadow: 0px 2px 0 rgba(0, 0, 0, 0.38);
`;

const LogoText = styled(BodyIntro)`
  /* font-weight: bold; */
  color: white;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.16);

  text-transform: uppercase;
  margin-left: 4px;
`;
