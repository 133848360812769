import React from "react";
import HeroSection from "../components/sections/HeroSection";
import Layout from "../components/layout/layout";

function IndexPage() {
  return (
    <Layout>
      <title>Budaya AI</title>
      <HeroSection />
    </Layout>
  );
}

export default IndexPage;
