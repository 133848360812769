export const menuData = [
  {
    title: "Contact",
    icon: "/images/icons/courses.svg",
    link: "mailto:support@budaya.site",
  },
  {
    title: "Login",
    icon: "/images/icons/account.svg",
    link: "https://dashboard.budaya.site",
  },
];
