import React from "react";
import styled, { keyframes } from "styled-components";
import { H1, MediumText } from "../styles/TextStyles";
import { themes } from "../styles/ColorStyles";
import HeroButton from "../buttons/HeroButton";
import MockupAnimation from "../animations/MockupAnimation";
import WaveBackground from "../backgrounds/WaveBackground";

function HeroSection() {
  return (
    <Wrapper>
      <WaveBackground />
      <ContentWrapper>
        <BgPattern />
        <TextWrapper>
          <Title>
            Digital Culture <br />
            Diagnostic Tool <br />
          </Title>
          <Description>
            Online assessment tools to diagnose, score, and predict your
            employee's digital attitudes using Artificial Intelligence (AI).
          </Description>

          <HeroButton
            title="Mulai Diagnosa"
            subtitle="Tuliskan cerita, jawab kuesioner dan dapatkan hasil instan"
            link="https://form.budaya.site/demo"
          />

          <SampleLink>
            <a href="https://form.budaya.site/demo/contoh">
              Contoh Hasil Diagnosa
            </a>
          </SampleLink>
        </TextWrapper>

        <MockupAnimation />
      </ContentWrapper>
    </Wrapper>
  );
}

export default HeroSection;

const textAnimation = keyframes`
  from { opacity: 0; transform: translateY(-20px) skew(5deg); }
  to {opacity: 1; transform: translateY(0px) skew(0deg);}
`;

const Wrapper = styled.div`
  height: 800px;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 200px 30px 0;
  display: grid;
  grid-template-columns: 360px auto;
  gap: 45px;
  z-index: 20;
  height: auto;

  @media (max-width: 768px) {
    grid-template-columns: 240px auto;
    gap: 60px;
  }

  @media (max-width: 450px) {
    grid-template-columns: auto;
    padding: 150px 20px 250px;
    gap: 30px;
  }
`;

const TextWrapper = styled.div`
  max-width: 360px;
  display: grid;
  gap: 24px;
  z-index: 20;

  animation: ${textAnimation} 0.5s ease-in-out;

  ::nth-child(1) {
    animation-delay: 0s;
  }
  ::nth-child(2) {
    animation-delay: 0.2s;
  }
  ::nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const Title = styled(H1)`
  color: ${themes.dark.text1};

  @media (max-width: 450px) {
    font-size: 36px;
  }

  @media (max-width: 450px) {
    font-size: 48px;
  }
`;

const Description = styled(MediumText)`
  color: ${themes.dark.text1};
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
`;

const SampleLink = styled(MediumText)`
  display: block;
  margin-top: 8px;
  a {
    color: white;
    padding-bottom: 4px;
    border-bottom: 1px solid;
    &:hover {
    }
  }
`;

const BgPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/images/5-dots/5-dots.png") repeat;
  opacity: 0.1;
  z-index: 0;
  pointer-events: none;
  filter: brightness(1.2);
`;
