import React from "react";
import styled from "styled-components";
import { Caption2, SmallText } from "../styles/TextStyles";

function HeroButton(props) {
  const { title, subtitle, link } = props;
  return (
    <a href={link || "https://google.com"}>
      <Wrapper>
        <IconWrapper>
          <Icon src="/images/icons/book.svg" />
          {/* <Ring src="/images/icons/icon-ring.svg" /> */}
        </IconWrapper>
        <TextWrapper>
          <Title>{title || "Test My Score"}</Title>
          <Subtitle>
            {subtitle || "Get accurate personality test result"}
          </Subtitle>
        </TextWrapper>
      </Wrapper>
    </a>
  );
}

export default HeroButton;

const Wrapper = styled.div`
  width: 280px;
  height: 77px;
  padding: 12px;
  background: linear-gradient(180deg, #ffffff 0%, #d9dfff 100%);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(23, 0, 102, 0.2),
    inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 53px auto;
  justify-content: start;
  align-items: center;
  gap: 20px;

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.38),
      0px 30px 60px rgba(23, 0, 102, 0.5),
      inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.5);
    transform: translateY(-3px);
  }
`;

const Title = styled(Caption2)`
  color: black;
  font-weight: bold;
`;

const Subtitle = styled(SmallText)`
  color: black;
  opacity: 0.7;
`;

const TextWrapper = styled.div`
  display: grid;
  gap: 4px;
`;

const IconWrapper = styled.div`
  width: 45px;
  height: 45px;
  background: linear-gradient(200.44deg, #346ec8 13.57%, #3d8e95 98.38%);
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  justify-self: center;
  box-sizing: border-box;
  position: relative;

  ${Wrapper}:hover & {
    filter: hue-rotate(10deg) brightness(1.2);
  }
`;

const Ring = styled.img`
  position: absolute;
  top: -15px;
  left: -16px;
  opacity: 0;
  filter: hue-rotate(250deg) brightness(1.2);

  ${Wrapper}:hover & {
    transform: rotate(30deg) scale(1.1) translate(1px, 1px);
    opacity: 1;
  }
`;

const Icon = styled.img`
  width: 29px;
  height: 29px;
`;
