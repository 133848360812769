import React from "react";
import styled from "styled-components";
import { GlobalStyles } from "../styles/GlobalStyles";
import "./reset.css";
import "./layout.css";

import Header from "./header";

function Layout({ children }) {
  return (
    <Wrapper>
      <Header />
      <GlobalStyles />
      <main>{children}</main>
    </Wrapper>
  );
}

export default Layout;

const Wrapper = styled.div``;
