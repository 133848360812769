import React from "react";
import styled from "styled-components";

export default function WaveBackground() {
  return (
    <Wrapper>
      <Background />
      <Wave
        src="/images/waves/hero-wave1.svg"
        style={{ top: "100px", filter: "hue-rotate(200deg)", opacity: 0.5 }}
      />
      <Wave src="/images/waves/hero-wave2.svg" style={{ top: "350px" }} />
      <BottomWave src="/images/waves/hero-wave3.svg" style={{ top: "550px" }} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 800px;
  overflow-x: hidden;
  pointer-events: none;
`;

const Wave = styled.img`
  position: absolute;
  z-index: -1;

  @media (min-width: 1440px) {
    width: 100%;
  }
`;

const Background = styled.div`
  position: absolute;
  background: linear-gradient(45deg, #c0c5cb 0%, #7e9ec7 45%, #7e9ec7 100%);
  z-index: -1;
  width: 100%;
  height: 100vh;
`;

const BottomWave = styled(Wave)`
  @media (prefers-color-scheme: dark) {
    content: url("/images/waves/hero-wave3-dark.svg");
  }
`;
