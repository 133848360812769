import React from "react";
import styled from "styled-components";

export default function MockupAnimation(props) {
  return (
    <Wrapper>
      <div className="mockup mockup1">
        <img
          className="mockup__img"
          src="/images/mockups/mockup9.png"
          alt="budaya-mockup-1"
        />
      </div>
      <div className="mockup mockup2">
        <img
          className="mockup__img"
          src="/images/mockups/mockup5.png"
          alt="budaya-mockup-2"
        />
      </div>
      <div className="mockup mockup3">
        <img
          className="mockup__img"
          src="/images/mockups/mockup1.png"
          alt="budaya-hasil-analisa"
        />
      </div>
      <div className="mockup mockup4">
        <img
          className="mockup__img"
          src="/images/mockups/mockup6.png"
          alt="budaya-mockup-4"
        />
      </div>
      <div className="mockup mockup5">
        <img
          className="mockup__img"
          src="/images/mockups/mockup4.png"
          alt="budaya-mockup-5"
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  /* background-color: tomato; */
  perspective: 2500;
  perspective-origin: top left;
  transform-style: flat;
  transform: scale(0.85);
  top: -50px;

  :after {
    content: "";
    display: table;
    clear: both;
  }

  @media (max-width: 768px) {
    transform: scale(0.6);
    transform-origin: top left;
    top: unset;
  }

  @media (max-width: 450px) {
    transform: scale(0.4);
  }

  .mockup {
    transform: rotateY(-20deg) rotateX(20deg);
    transform-origin: bottom left;
  }

  .mockup {
    position: absolute;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover .mockup {
    transform: rotateX(0deg) rotateY(0deg);
    &.mockup1 {
      transform: translate(-30px, -30px);
      transition-delay: 0.1s;
    }
    &.mockup2 {
      transform: translate(0, -50px);
      transition-delay: 0.2s;
    }
    &.mockup3 {
      transition-delay: 0s;
    }
    &.mockup4 {
      transform: translate(-120px, 90px);
      transition-delay: 0.1s;
    }
    &.mockup5 {
      transform: translate(-90px, 90px);
      transition-delay: 0.2s;
    }
    :hover {
      filter: brightness(110%) saturate(120%);
      /* top: 0;
      left: 5%;
      width: 80%; */
      /* height: auto; */
      /* z-index: 30; */
    }
  }

  .mockup1 {
    width: 183px;
    /* height: 120px; */
    height: auto;
    left: 0;
    top: 0px;
    background-position: center;
    background-size: fit;

    background: radial-gradient(
      218.51% 281.09% at 100% 100%,
      #346ec8 0%,
      #346ec8 45.83%,
      #3d8e95 100%
    );
    border: 0.273134px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 16.3881px 32.7761px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(21.8507px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16px;
  }

  .mockup2 {
    width: 183px;
    height: 120px;
    left: 214px;
    top: 0px;

    background: linear-gradient(
      198.85deg,
      #4316db 12.72%,
      #9076e7 54.49%,
      #a2eeff 100.01%
    );
    border: 0.27304px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 8.19119px 16.3824px rgba(0, 0, 0, 0.1),
      0px 16.3824px 32.7648px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(21.8432px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16.3824px;
  }

  .mockup3 {
    width: 701px;
    height: 428px;
    left: 37px;
    top: 60px;

    background: rgba(23, 12, 61, 0.5);
    border: 0.342305px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(27.3844px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 6.8461px;
  }

  .mockup4 {
    width: 399px;
    /* height: 274px; */
    height: auto;
    left: 194px;
    top: 262px;

    background: rgba(39, 20, 62, 0.3);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(27.3844px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 14px;
  }

  .mockup5 {
    width: 412px;
    /* height: 274px; */
    height: auto;
    left: 616px;
    top: 262px;

    background: rgba(39, 20, 62, 0.2);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(27.3844px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 14px;
  }
  .mockup {
    overflow: hidden;
  }

  .mockup__img {
    width: 100%;
    height: auto;
    opacity: 0.9;
    object-fit: cover;
  }
`;
